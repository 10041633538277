import http from "../http-common";

class StreckenlieferantenDataService {
  getOne(id) {
    return http.get(`/streckenlieferanten/${id}`);
  }

  getAllByFilter(abteilung, search, gelistet) {
    return http.get(`/streckenlieferanten?abteilung=${abteilung}&search=${search}&gelistet=${gelistet}`);
  }

  getAllAbteilungen() {
    return http.get("/streckenlieferanten/abteilungen");
  }
}

export default new StreckenlieferantenDataService();