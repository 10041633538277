import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import StreckenlieferantenDataService from "../services/streckenlieferanten.service";
import { CSVLink } from "react-csv";
var XLSX = require("xlsx");

export default function Streckenlieferanten() {
  let { id } = useParams();

  const [streckenlieferant, setStreckenlieferant] = useState([]);
  const [streckenlieferantCSV, setStreckenlieferantCSV] = useState([]);
  const [streckenlieferantXLSX, setStreckenlieferantXLSX] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    retrieveStreckenlieferant(id);
  }, []);

  const retrieveStreckenlieferant = async (id) => {
    StreckenlieferantenDataService.getOne(id)
      .then(response => {
        if (response.data.id) {
          if (response.data.streckenlieferanten_kundens.length > 0)
            response.data.gelistet = "Ja";
          else
            response.data.gelistet = "Nein";
          delete response.data.streckenlieferanten_kundens;
          setStreckenlieferant(response.data);

          let clonecsv = structuredClone(response.data);
          clonecsv.ansprechpartner_telefon = "\t" + clonecsv.ansprechpartner_telefon;
          clonecsv.ansprechpartner_fax = "\t" + clonecsv.ansprechpartner_fax;
          clonecsv.ansprechpartner_handy = "\t" + clonecsv.ansprechpartner_handy;
          setStreckenlieferantCSV([clonecsv]);

          let clonexlsx = structuredClone(response.data);
          setStreckenlieferantXLSX([clonexlsx]);
        }
        else
          window.location.href = "/";
      })
      .catch(error => {
      });
  }

  const ExportXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(streckenlieferantXLSX);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, streckenlieferant.id.toString());

    let objectMaxLength = [];
    const header = Object.keys(streckenlieferantXLSX[0]);
    for (var i = 0; i < header.length; i++) {
      objectMaxLength[i] = header[i].length + 5;
    }
    streckenlieferantXLSX.map(jsonData => {
      Object.entries(jsonData).map(([, v], idx) => {
        let columnValue = v.toString();
        objectMaxLength[idx] = objectMaxLength[idx] >= columnValue.length ? objectMaxLength[idx] : columnValue.length;
      })
    })
    const wscols = objectMaxLength.map((w) => ({ width: w }))
    worksheet["!cols"] = wscols;

    XLSX.writeFile(workbook, streckenlieferant.id + ".xlsx");
  }

  return (
    <div class="row" style={{ marginRight: "auto", marginLeft: "auto" }}>
      <div class="col-md-2"></div>
      {streckenlieferant.id &&
        <div class="container">
          <div class="row">
            <div class="col-lg-9 print-order-1">
              <div class="col-md-3 modal-body text-center"></div>
              <div class="row articleElem p-4">
                <div class="col-xl-11 col-md-12 col-sm-12">
                  <div class="row">
                    <span class="h6 col-md-6"><b>Gelistet</b></span>
                    <span class="h6 col-md-6">{streckenlieferant.gelistet}</span>
                  </div>
                  <br />
                  <div class="row">
                    <span class="h6 col-md-6"><b>Einkaufsbereich</b></span>
                    <span class="h6 col-md-6">{streckenlieferant.einkaufsbereich}</span>
                  </div>
                  <br />
                  <div class="row">
                    <span class="h6 col-md-6"><b>Lieferantennummer</b></span>
                    <span class="h6 col-md-6">{streckenlieferant.id}</span>
                  </div>
                  <br />
                  <div class="row">
                    <span class="h6 col-md-6"><b>Firma</b></span>
                    <span class="h6 col-md-6">{streckenlieferant.name}<br />{streckenlieferant.strasse}<br />{streckenlieferant.plz} {streckenlieferant.ort}</span>
                  </div>
                  <br />
                  <div class="row">
                    <span class="h6 col-md-6"><b>Abteilung</b></span>
                    <span class="h6 col-md-6">{streckenlieferant.abteilung}</span>
                  </div>
                  {(streckenlieferant.ansprechpartner_name !== '' ||
                    streckenlieferant.ansprechpartner_telefon !== '' ||
                    streckenlieferant.ansprechpartner_fax !== '' ||
                    streckenlieferant.ansprechpartner_handy !== '' ||
                    streckenlieferant.ansprechpartner_email !== '' ||
                    streckenlieferant.ansprechpartner_web !== '') &&
                    <span>
                      <br />
                      <div class="row">
                        <span class="h6 col-md-6"><b>Ansprechpartner</b></span>
                        <span class="h6 col-md-6">{streckenlieferant.ansprechpartner_name !== '' && <span>{streckenlieferant.ansprechpartner_name}<br /></span>}
                          {streckenlieferant.ansprechpartner_telefon !== '' && <span>Telefon {streckenlieferant.ansprechpartner_telefon}<br /></span>}
                          {streckenlieferant.ansprechpartner_fax !== '' && <span>Fax {streckenlieferant.ansprechpartner_fax}<br /></span>}
                          {streckenlieferant.ansprechpartner_handy !== '' && <span>Mobil {streckenlieferant.ansprechpartner_handy}<br /></span>}
                          {streckenlieferant.ansprechpartner_email !== '' && <span>Email {streckenlieferant.ansprechpartner_email}<br /></span>}
                          {streckenlieferant.ansprechpartner_web !== '' && <span>Web {streckenlieferant.ansprechpartner_web}</span>}
                        </span>
                      </div>
                    </span>
                  }
                  {(streckenlieferant.bestelldaten_tel !== '' ||
                    streckenlieferant.bestelldaten_fax !== '' ||
                    streckenlieferant.bestelldaten_email !== '') &&
                    <span>
                      <br />
                      <div class="row">
                        <span class="h6 col-md-6"><b>Bestelldaten</b></span>
                        <span class="h6 col-md-6">
                          {streckenlieferant.bestelldaten_tel !== '' && <span>Telefon {streckenlieferant.bestelldaten_tel}<br /></span>}
                          {streckenlieferant.bestelldaten_fax !== '' && <span>Fax {streckenlieferant.bestelldaten_fax}<br /></span>}
                          {streckenlieferant.bestelldaten_email !== '' && <span>Email {streckenlieferant.bestelldaten_email}</span>}
                        </span>
                      </div>
                    </span>
                  }
                  {streckenlieferant.konditionen !== '' &&
                    <span>
                      <br />
                      <div class="row">
                        <span class="h6 col-md-6"><b>Konditionen</b></span>
                        <span class="h6 col-md-6" style={{ whiteSpace: "pre" }}>{streckenlieferant.konditionen}</span>
                      </div>
                    </span>
                  }
                  {streckenlieferant.mindestauftragswert !== '' &&
                    <span>
                      <br />
                      <div class="row">
                        <span class="h6 col-md-6"><b>Mindestauftragswert</b></span>
                        <span class="h6 col-md-6">{streckenlieferant.mindestauftragswert}</span>
                      </div>
                    </span>
                  }
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="row pt-2">
                <div class="col-lg-12 col-md-6">
                  <div class="tiles bg-white" style={{ height: "60px", marginTop: "20px" }}>
                    <div class="row mx-2" style={{ height: "100%" }}>
                      <div class="col-sm-3 d-flex align-items-center px-1 print-flex-maxwidth-50">
                        <img
                          src={"https://portal.stroetmann.de/bilder/output/Messeportal/logos streckenlieferanten/" + streckenlieferant.id + ".jpg"}
                          loading="lazy"
                          decoding="async"
                          class="img-fluid print-maxheight-65 print-marginbottom-20"
                          style={{ maxHeight: "3.5rem", visibility: "hidden" }}
                          onLoad={(e) => e.currentTarget.style.visibility = "visible"}
                          onError={(e) => e.currentTarget.style.visibility = "hidden"}
                        />
                      </div>
                      <div class="col-sm-9 d-flex align-items-center px-1 print-fontsize-105 print-flex-maxwidth-100">{streckenlieferant.name}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-6 print-display-unset" style={{ display: "none" }}>
                  <div class="tiles bg-white" style={{ height: "140px", marginTop: "20px" }}>
                    <div class="row mx-2" style={{ height: "100%" }}>
                      <img src="/images/AnschriftLStroetmann.png" style={{ maxHeight: "10.5rem" }} />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-6 print-display-none">
                  <div class="tiles bg-white" style={{ height: "60px", marginTop: "20px" }}>
                    <Link class="text-dark" to="" onClick={() => { navigate(-1); }}>
                      <div class="row mx-2" style={{ height: "100%" }}>
                        <div class="col-sm-3 d-flex align-items-center px-1">
                          <img src="/images/back_d.svg" style={{ maxHeight: "3.5rem" }} />
                        </div>
                        <div class="col-sm-6 d-flex align-items-center px-1">Zurück zu<br />Streckenlieferanten-<br />übersicht</div>
                        <div class="col-sm-3 d-flex align-items-center px-1">
                          <img src="/images/suppliers.svg" style={{ maxHeight: "3.5rem" }} />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-lg-12 col-md-6 print-display-none">
                  <div class="tiles bg-white" style={{ height: "60px", marginTop: "20px" }}>
                    <Link class="text-dark" to="" onClick={() => { window.print(); }}>
                      <div class="row mx-2" style={{ height: "100%" }}>
                        <div class="col-sm-3 d-flex align-items-center px-1">
                          <img src="/images/print.png" style={{ maxHeight: "3.5rem", marginLeft: "5px", marginTop: "5px" }} />
                        </div>
                        <div class="col-sm-6 d-flex align-items-center px-1">Drucken</div>
                        <div class="col-sm-3 d-flex align-items-center px-1"></div>
                      </div>
                    </Link>
                  </div>
                </div>
                {/*
                <div class="col-lg-12 col-md-6 print-display-none">
                  <div class="tiles bg-white" style={{ height: "60px", marginTop: "20px" }}>
                    <CSVLink class="text-dark" data={streckenlieferantCSV} filename={streckenlieferant.id + ".csv"} separator={";"}>
                      <div class="row mx-2" style={{ height: "100%" }}>
                        <div class="col-sm-3 d-flex align-items-center px-1"></div>
                        <div class="col-sm-6 d-flex align-items-center px-1">CSV</div>
                        <div class="col-sm-3 d-flex align-items-center px-1"></div>
                      </div>
                    </CSVLink>
                  </div>
                </div>
                <div class="col-lg-12 col-md-6 print-display-none">
                  <div class="tiles bg-white" style={{ height: "60px", marginTop: "20px" }}>
                    <Link class="text-dark" to="" onClick={ExportXLSX}>
                      <div class="row mx-2" style={{ height: "100%" }}>
                        <div class="col-sm-3 d-flex align-items-center px-1"></div>
                        <div class="col-sm-6 d-flex align-items-center px-1">XLSX</div>
                        <div class="col-sm-3 d-flex align-items-center px-1"></div>
                      </div>
                    </Link>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      }
      <div class="col-md-2"></div>
    </div>
  );
}