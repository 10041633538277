import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import UsersDataService from "./services/users.service";
import "./bootstrap.min.css";
import "./App.css";
import "./all.min.css";
import "./own.css";
import "./custom.css";

import Streckenlieferanten from "./components/streckenlieferanten.component";
import Streckenlieferant from "./components/streckenlieferant.component";

export default function App() {
  const [user, setUser] = useState("");
  const [streckenlieferanten, setStreckenlieferanten] = useState([]);
  const [abteilungen, setAbteilungen] = useState([]);

  const [gelistetFilter, setGelistetFilter] = useState(sessionStorage.getItem("gelistetFilter") === null ? "true" : sessionStorage.getItem("gelistetFilter"));
  const [selectedAbteilung, setSelectedAbteilung] = useState(sessionStorage.getItem("selectedAbteilung") === null ? "" : sessionStorage.getItem("selectedAbteilung"));
  const [searchName, setSearchName] = useState(sessionStorage.getItem("searchName") === null ? "" : sessionStorage.getItem("searchName"));

  const retrieveUser = async () => {
    UsersDataService.getUser()
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        if (error.response.status === 403) {
          window.location.href = "https://kdportal.stroetmann.de";
        }
      });
  }

  useEffect(() => {
    retrieveUser();
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Streckenlieferanten
          streckenlieferanten={streckenlieferanten}
          setStreckenlieferanten={setStreckenlieferanten}
          abteilungen={abteilungen}
          setAbteilungen={setAbteilungen}
          gelistetFilter={gelistetFilter}
          setGelistetFilter={setGelistetFilter}
          selectedAbteilung={selectedAbteilung}
          setSelectedAbteilung={setSelectedAbteilung}
          searchName={searchName}
          setSearchName={setSearchName}
        />} />
        <Route path="/streckenlieferanten/:id" element={<Streckenlieferant />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};