import React, { useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import StreckenlieferantenDataService from "../services/streckenlieferanten.service";
import debounce from 'lodash.debounce';

export default function Streckenlieferanten({
  streckenlieferanten,
  setStreckenlieferanten,
  abteilungen,
  setAbteilungen,
  gelistetFilter,
  setGelistetFilter,
  selectedAbteilung,
  setSelectedAbteilung,
  searchName,
  setSearchName
}) {

  const didMountRef = useRef(false);

  const retrieveAbteilungen = async () => {
    StreckenlieferantenDataService.getAllAbteilungen()
      .then(response => {
        setAbteilungen(response.data);
      })
      .catch(error => {
      });
  }

  const retrieveStreckenlieferantenByFilterDebounce = useMemo(() => debounce((selectedAbteilung, searchName, gelistetFilter) => {
    StreckenlieferantenDataService.getAllByFilter(selectedAbteilung, searchName, gelistetFilter)
      .then(response => {
        setStreckenlieferanten(response.data);
      })
      .catch(error => {
      });
  }, 500), []);

  const retrieveStreckenlieferantenByFilter = async () => {
    StreckenlieferantenDataService.getAllByFilter(selectedAbteilung, searchName, gelistetFilter)
      .then(response => {
        setStreckenlieferanten(response.data);
      })
      .catch(error => {
      });
  };

  useEffect(() => {
    if (streckenlieferanten.length === 0) {
      retrieveStreckenlieferantenByFilter();
      retrieveAbteilungen();
    }
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      retrieveStreckenlieferantenByFilter();
    }
  }, [gelistetFilter]);

  useEffect(() => {
    if (didMountRef.current) {
      retrieveStreckenlieferantenByFilter();
    }
  }, [selectedAbteilung]);

  useEffect(() => {
    if (didMountRef.current) {
      retrieveStreckenlieferantenByFilterDebounce(selectedAbteilung, searchName, gelistetFilter);
    }
    didMountRef.current = true;
  }, [searchName]);

  const onChangeGelistetFilter = (e) => {
    if (gelistetFilter === "true") {
      sessionStorage.setItem("gelistetFilter", "");
      setGelistetFilter("");
    } else {
      sessionStorage.setItem("gelistetFilter", "true");
      setGelistetFilter("true");
    }
  }

  const onChangeAbteilung = (e) => {
    sessionStorage.setItem("selectedAbteilung", e.target.value);
    setSelectedAbteilung(e.target.value);
  }

  const onChangeSearchName = (e) => {
    sessionStorage.setItem("searchName", e.target.value);
    setSearchName(e.target.value);
  };

  const onKeyPressSearchName = (e) => {
    if (e.key === 'Enter') {
      retrieveStreckenlieferantenByFilter();
    }
  }

  const clearSearchName = () => {
    sessionStorage.setItem("searchName", "");
    setSearchName("");
  }

  return (
    <div class="row" style={{ marginRight: "auto", marginLeft: "auto" }}>
      <div class="col-md-2"></div>
      <div class="container">
        <div class="col-xl-12 col-lg-9" /*style={{ position: "sticky", top: "0", background: "white", zIndex: "1", marginLeft: "-5px", maxWidth: "none", width: "102%" }}*/>
          <div class="row">
            <div class="col-sm-6 m-auto">
              <div class="row">
                <div class="col-sm-2 pl-5">
                  <label class="switch">
                    <input type="checkbox" onChange={onChangeGelistetFilter} defaultchecked="checked" checked={gelistetFilter} />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="col-sm-10 mb-1">
                  nur gelistete zeigen
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="input-group mb-3">
                <span class="input-group-text" style={{ height: "30px" }}>
                  <img class="img-fluid h-100" src="/images/search.svg" />
                </span>
                <input
                  class="form-control"
                  style={{ height: "30px" }}
                  value={searchName}
                  placeholder="Nach Streckenlieferantennummer oder -namen suchen..."
                  onChange={onChangeSearchName}
                  onKeyPress={onKeyPressSearchName} />
                <span class="input-group-text" style={{ height: "30px" }}>
                  <i class="fa fa-times" style={{ color: "red" }} onClick={clearSearchName} />
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md text-left pt-4">
              {abteilungen && abteilungen.length >= 1 &&
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      value=""
                      name="abteilung"
                      id="abteilung_alle"
                      checked={selectedAbteilung === ""}
                      onChange={onChangeAbteilung} />
                    <label
                      class="form-check-label"
                      for="abteilung_alle">
                      Alle Abteilungen
                    </label>
                  </div>
                  {abteilungen.map((abteilung, index) => (
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        value={abteilung.abteilung}
                        name="abteilung"
                        id={"abteilung_" + abteilung.abteilung}
                        checked={selectedAbteilung === abteilung.abteilung}
                        onChange={onChangeAbteilung} />
                      <label
                        class="form-check-label"
                        for={"abteilung_" + abteilung.abteilung}>
                        {abteilung.abteilung}
                      </label>
                    </div>
                  ))
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <br></br>
        <div class="row">
          {streckenlieferanten && streckenlieferanten.map((streckenlieferant, index) => (
            <div class="col-md-4 col-xl-3 pb-4">
              <div class="container rounded m-1 tiles bg-white">
                <Link class="text-dark" to={"/streckenlieferanten/" + streckenlieferant.id}>
                  <div class="text-dark">
                    <div class="row p-4 uppertile">
                      <div class="col-md d-flex justify-content-center">
                        <img
                          src={"https://portal.stroetmann.de/bilder/output/Messeportal/logos streckenlieferanten/" + streckenlieferant.id + ".jpg"}
                          loading="lazy"
                          decoding="async"
                          class="img-fluid m-auto"
                          style={{ maxHeight: "5rem", visibility: "hidden" }}
                          onLoad={(e) => e.currentTarget.style.visibility = "visible"}
                          onError={(e) => e.currentTarget.style.visibility = "hidden"}
                        />
                      </div>
                    </div>
                    <div class="row lowertile">
                      <div class="col-md-9">{streckenlieferant.name}</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <div class="container rounded tiles subtiles">{streckenlieferant.abteilung}</div>
              </div>
              <div class="doneBtnDiv">
                <div class="dlk-radio">
                  <label class="text-dark btn doneBtn">
                    <input
                      class="form-control"
                      type="checkbox"
                      defaultValue={0}
                      defaultchecked="checked"
                      checked={streckenlieferant.streckenlieferanten_kundens.length > 0 ? "true" : ""} />
                    <i class="fa fa-check" style={{ color: streckenlieferant.streckenlieferanten_kundens.length > 0 ? "#09c200" : "red" }} />
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  );
}